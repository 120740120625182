import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/wamp/www/docz/sgc-documentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "classes--events"
    }}>{`Classes / Events`}</h1>
    <h2 {...{
      "id": "adding-a-classevent"
    }}>{`Adding A Class/Event`}</h2>
    <h2 {...{
      "id": "editing-an-existing-classevent"
    }}>{`Editing An Existing Class/Event`}</h2>
    <h2 {...{
      "id": "repeated-eventsclases"
    }}>{`Repeated Events/Clases`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      